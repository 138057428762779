import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import '@/assets/styles/reset.css'
//import 'element-ui/lib/theme-chalk/index-rem.css';//修改px 为rem  为适配指定分辨率
import 'element-ui/lib/theme-chalk/index.css';
// import 'element-ui/lib/theme-chalk/index.css';
//import 'ant-design-vue/dist/antd-rem.css';////修改px 为rem  为适配指定分辨率
import 'ant-design-vue/dist/antd.css';
// import 'lib-flexible';
import qs from 'qs';
import dataV from '@jiaminghi/data-view';
import scroll from 'vue-seamless-scroll';
import * as echarts from 'echarts';
import china from '@/common/map/china.json';
import 'echarts/theme/macarons.js';
import fullscreen from 'vue-fullscreen'//全屏
Vue.prototype.$echarts = echarts
echarts.registerMap('china', china)
Vue.use(dataV)
Vue.prototype.$qs = qs;
Vue.use(scroll)
import {
    Breadcrumb,
    Pagination,
    Spin,
    Button,
    Modal,
    Form,
    FormModel,
    Input,
    Rate,
    Icon,
    Progress,
    Dropdown,
    Cascader,
    Divider,
    DatePicker,
    Collapse,
    Upload,
    Tabs,
    Tag,
    Empty
} from 'ant-design-vue'
Vue.config.productionTip = false

const V = [
    Breadcrumb,
    Pagination,
    Spin,
    Button,
    Modal,
    Form,
    FormModel,
    Input,
    Rate,
    Icon,
    Progress,
    Dropdown,
    Cascader,
    Divider,
    DatePicker,
    Collapse,
    Upload,
    Tabs,
    Tag,
    Empty
]
for (let i=0;i<V.length;i++){
  Vue.use(V[i])
}
Vue.use(ElementUI);
Vue.use(fullscreen)
// Vue.use(dataV);
// Vue.use(Breadcrumb)
// Vue.use(Pagination)
// Vue.use(Spin)
// Vue.use(Button)
// Vue.use(Modal)
// Vue.use()
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
