<template>
  <div class="head-container" >
    <!-- :class="{'header_nav_fixed':fixedShow}" -->
    <header class="header_nav" ref="header_nav">
      <a href="/"><img src="@/assets/images/logo1.png" class="logo" /></a>
      <!--    <header-nav />-->
      <div class="select" >
          <div class="select-title" @mouseenter="titleMouseenter" @mouseleave="titleMouseleave" >{{ cityName ?cityName:'全部' }}
            <i class="el-icon-caret-bottom"></i>
          </div>
          <div class="select-option" :class="{'select-show':show}" @mouseenter="titleMouseenter" @mouseleave="titleMouseleave">
            <div class="select-option-item"  >
                  <div class="select-option-item-title" >
                    <div  class="select-option-item-title-span2" :class="{'li-city2':'' == cityName}"  @click="cityClick(1,'')">全部</div>
                  </div>
              </div>
              <div class="select-option-item" v-for="(item,index) in options" :key="index+'c'" >
                  <div class="select-option-item-title" >
                    <div v-if="index<9" class="select-option-item-title-span2" :class="{'li-city2':item.label == cityName}"  @click="cityClick(1,item.label)">{{item.label}}</div>
                    <div v-else class="select-option-item-title-span" :class="{'li-city':item.label == cityName}"  @click="cityClick(1,item.label)">{{item.label}}</div>
                  </div>
                  <div class="select-option-item-ul">
                    <template v-for="(items,indexs) in item.children"  >
                      <div v-if="index < 9" class="select-option-item-ul-li2" :key="indexs+'s'"  @click="cityClick(2,items.label)"  :class="{'li-city':items.label == cityName}">{{ items.label }}</div>
                      <div v-else class="select-option-item-ul-li" :key="indexs+'s'"  @click="cityClick(2,items.label)"  :class="{'li-city':items.label == cityName}">{{ items.label }}</div>
                    </template>
                  </div>
              </div>
          </div>
      </div>
      <div class="search">
        <!-- <div class="search-select"> -->
          <!-- <el-dropdown trigger="click" @command="searchSel">
            <span class="el-dropdown-link">
              {{ searchModal.value }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{type:1,value:'研学线路'}" >研学线路</el-dropdown-item>
              <el-dropdown-item :command="{type:2,value:'研学基地'}" >研学基地</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown> -->
        <!-- </div> -->
        <div class="search-input">
          <input class="search-input-name text-overflow-1" v-model="searchName"  type="text" name="" id="">
        </div>
        <div class="search-btn" @click="searchClick">
            搜索
        </div>
      </div>
	  <div class="login-header" style="display:;">
	      <button class="btn-login" style="width: 0.6063rem;" @click="openwin('https://admin.huangheyanxue.com.cn/dp/')">数据看板</button>
	  </div>
      <div class="login-header"  @mouseenter="infoMouseenter" @mouseleave="infoMouseleave" style="display:none;">
          <div  class="login-header-userInfo" v-if="userInfo">
              <img class="userInfo-img" :src="userInfo.head_portrait" alt="">
              <!-- <span class="userInfo-title">{{userInfo.nickname}}爱哭的急啊卡省的</span> -->
          </div>
          <div class="userInfo-option" :class="{'user-show':userShow && userInfo}">
            <span class="userInfo-option-item"  @click="go('/user')"><span>个人中心</span> </span>
            <span class="userInfo-option-item" @click="logout"><span> 退出登录</span></span>
          </div>
          <button v-if="!userInfo" class="btn-login" @click="dialogVisible = true"> 登录</button>
          <!-- <button  class="btn-login" @click="dialogVisible = true"> 登录</button> -->
      </div>
      <!-- <div class=""></div> -->
      <div class="position-right">
        <a class="item item1">
          <div class="icon">
            <img
              src="@/assets/images/wechat.png"
              class="static"
              alt=""
              srcset=""
            />
            <img
              src="@/assets/images/wechat_1.png"
              class="hover"
              alt=""
              srcset=""
            />
          </div>
          <span class="text1">小程序</span>
          <div class="pop-content">
            <div class="pop-item">
              <img src="@/assets/images/qr/xcx.jpg" alt="" />
              <div class="text">扫码访问小程序</div>
            </div>
          </div>
        </a>
        <a class="item">
          <div class="icon">
            <img
              src="@/assets/images/wechat_d.png"
              class="static"
              alt=""
              srcset=""
            />
            <img
              src="@/assets/images/wechat_d_1.png"
              class="hover"
              alt=""
              srcset=""
            />
          </div>
          <span class="text1">微信公众号</span>
          <div class="pop-content">
            <div class="pop-item">
              <img src="@/assets/images/qr/gzh.jpg" alt="" />
              <div class="text">扫码访问微信公众号</div>
            </div>
          </div>
        </a>
        <a class="item" v-if="userInfo.token" @click="go('/user')">
          <div class="icon">
            <img
              :src="userInfo.head_portrait ?userInfo.head_portrait: defaultImg"
              class="static"
              alt=""
              srcset=""
            />
            <img
              :src="userInfo.head_portrait?userInfo.head_portrait: defaultImg"
              class="hover"
              alt=""
              srcset=""
            />
          </div>
          <div class="text1">个人中心</div>
        </a>
        <a class="item" target="_blank" href="https://shenbao.huangheyanxue.com.cn" :class="{item2:!isActive}">
          <div class="icon">
            <img
              src="@/assets/images/shenbao.png"
              class="static"
              alt=""
              srcset=""
            />
            <img
              src="@/assets/images/shenbao_1.png"
              class="hover"
              alt=""
              srcset=""
            />
          </div>
          <div class="text1"  >课程申报</div>
        </a>
        <a class="item top" :class="{ topActive: isActive,item2:isActive }" @click="goTop">
          <div class="icon">
            <img
              src="@/assets/images/top.png"
              class="static"
              alt=""
              srcset=""
            />
            <img
              src="@/assets/images/top_1.png"
              class="hover"
              alt=""
              srcset=""
            />
          </div>
          <div class="text1">回顶部</div>
        </a>
        <!-- <a class="item top" :class="{ topActive: isActive,item2:isActive }" @click="goTop">
          <el-backtop class="top" visibility-height="200" target=".page-component__scroll .el-scrollbar__wrap"></el-backtop>
        </a> -->
      </div>
    </header>
    <div class="menu">
      <div class="menu-ul">
          <div class="menu-ul-item" :class="{'li-act':path ==item.path}" v-for="(item,index) in list" :key="index" @click="nextClick(item,index)">
            <div class="menu-ul-item-title" :class="{'menu-ul-item-act':path ==item.path}">{{ item.title }}</div>
            <!-- <div class="menu-ul-item-xian"></div> -->
        </div>
      </div>
   <!-- <ul class="index-header-list">
        <li :class="{'li-act':path ==item.path}" v-for="(item,index) in list" :key="item.id" @click="nextClick(item,index)">
          {{ item.title }}
        </li>
      </ul> -->
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="3.125rem"
      :show-close="false"
      :before-close="handleClose">
      <div class="login">
          <div v-show="loginType">
            <div class="login-close" @click="dialogVisible = false">
                <i class="el-icon-error" size="30"></i>
                <!-- <div></div> -->
            </div>
            <div class="login-title">账号登录</div>
            <div class="login-item login-phone"> <el-input v-model="phoneNum" placeholder="请输入手机号"></el-input></div>
            <div class="login-item login-code">
              <el-input v-model="yzmCode" placeholder="手机验证码"></el-input>
              <div class="login-code-btn" :class="{yzmDef: isYzmStatus }"  @click="yzmClick"> {{ yzmMsg }}</div>
            </div>
            <div class="login-item login-btns" @click="submit"> 
              <div  >登录</div>
            </div>
          </div>
          <div v-if="!loginType">
            <!-- <div class="login-title">扫码登录</div> -->
            <div class="login-qr">
              <iframe style="width:100%;height:100%; " :src="'https://open.weixin.qq.com/connect/qrconnect?appid=wx2f218e779e19cb4e&scope=snsapi_login&redirect_uri=https://www.huangheyanxue.com.cn&state=&login_type=jssdk&style=&self_redirect=default&href=//www.huangheyanxue.com.cn/wx.css?v='+getNowDate()" frameborder="0"></iframe>
            </div>
          </div>
          <div v-if="loginType" @click="loginType = false" class="login-item login-wechat">
            <img class="login-wechat-img" src="@/assets/images/login/watch.png" alt="">
            微信登录
          </div>
          <div v-if="!loginType" @click="loginType = true" class="login-item login-mobile">
            <img class="login-wechat-img" src="@/assets/images/login/phone.png" alt="">
            手机号登录
          </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCode, submit,getUserInfo,areaAll } from "@/service/api";

export default {
  name: "IndexHeader",
  components: {
    // HeaderNav
  },
  props:{
    searchName:{
      type:String,
      default:''
    },
    // city:Object,
    // options:{
    //   type:Array,
    //   default:[]
    // },
    cityChange:Function,
    searchChange:Function
  },
  watch:{
    city(){
      // console.log(this.city);
      // if (localStorage.getItem("cityName")) {
      //   this.city = JSON.parse(localStorage.getItem("cityName"));
      //   console.log(this.city);
      // }
      this.cityName = this.city?.label;
    },
  },
  created(){
    // console.log('刷新');
    // console.log(this.$route);
    // this.path = this.$router.history._startLocation
    this.path = this.$route.path
    // console.log(this.path);
    // console.log(this.city);
  },
  mounted() {
    var _this = this;
    // this.$message({
    //         type:'success',
    //         message:'测试',
    //         duration:0,
    //         showClose:true
    //       })

    if(localStorage.getItem('userInfo')){
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
    window.addEventListener("scroll", _this.handleScroll, true);
    if(_this.$route.query.code){
      // ?code=0218qg1w3d5LN03nHD3w3N5cLN28qg1w&state=
      // code=001YKOFa1qoksF0NcTHa19L8rn4YKOFY&state=
        // console.log(_this.$route.query.code);
        //扫码登录回调过来的 
        _this.getUserInfo(_this.$route.query.code);
    }
    // this.cityName = this.city?.label;
    if (localStorage.getItem("cityName")) {
      this.city = JSON.parse(localStorage.getItem("cityName"));
      // console.log(this.city);
    }
    //if(localStorage.getItem('areaAll')){
    //  var areaAlls = JSON.parse(localStorage.getItem('areaAll'));
      // console.log(areaAlls);
   //   this.options = areaAlls
   // }else{
        areaAll().then(res=>{
        // console.log(res);
        this.options =res.data;
        localStorage.setItem('areaAll',JSON.stringify(res.data));
      })
    //}
  },
  data() {
    const list = [
      {title: '首页', id: 1,type:1, path:'/'},
      {title: '研学基地', id: 1,type:1, path:'/camp'},
      {title: '研学线路', id: 1,type:1, path:'/itinerary'},
      {title: '研学课程', id: 1,type:1, path:'/course'},
      {title: '研学学校', id: 1,type:1, path:'/school'},
      {title: '黄河课堂', id: 2,type:1, path:'/classroom'},
      {title: '研学资讯', id: 2,type:1, path:'/article'},
	  {title: '精品课程', id: 5,type:1, path:'/jpkc'},
      // {title: '试听黄河', id: 3,type:1, path:'/map'},
      //{title: "课程申报", id: 5,type:2, path: "https://shenbao.huangheyanxue.com.cn" },
      {title: "关于我们", id: 4,type:1, path: "/about" },
    ];
    return {
      // searchModal:{
      //   type:1,
      //   value:'研学线路',
      //   searchName:''
      // },
      userShow:false,
      city:{level:'',label:''},
      phoneNum: "",
      yzmCode:'',
      loginType:true,
      dialogVisible: false,
      cityName:'',
      show:false,
      path:'',
      actIndex:0,
      userInfo:'',
      list,
      search: require("@/assets/images/search.png"),
      isActive: false,
      fixedShow:false,
      defaultImg:'https://zyyx.obs.cn-east-3.myhuaweicloud.com/picture_new/20220818/3ca597eab446e877f19b20eb71bc3a09.jpg',
      value: [],
      isYzmStatus: true,
      yzmMsg: "获取验证码",
      endTime: 60,
      timer: "60",
      options:[]
    };
  },
  methods: {
    searchSel({type,value}){
      this.searchModal.type = type;
      this.searchModal.value = value;

    },
    searchClick(){
      // console.log('点击搜索');
      if(this.searchName == ''){
        // this.$message.warngin('请输入搜索内容')
        this.$message.warning('请输入搜索内容');
        return false;
      }
      if(this.$route.path == '/search'){
        //如果是搜索页面 直接
        this.$emit('searchChange',this.searchName)
      }else{
        this.$router.push('/search?searchName='+this.searchName)
      }
      // if(this.$route.path)
    },
    getUserInfo(code=''){
		let that =this;
          //console.log(code);
          const loading = this.$loading({
          lock: false,
          text: '登录中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass:'customClass'
        });
        getUserInfo({code}).then(res=>{
          console.log(res);
            loading.close();
          if(res.code == 1) {
             that.$message.success(res.msg);
            // this.$message({
            //   title:res.msg,
            // });
            that.userInfo = res.data;
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            localStorage.setItem("token", res.data.token);
            // if(!res.data.mobile_phone) {
            //   //没有手机号 跳转绑定手机号页面
            //   //console.log('没有手机号 跳转绑定手机号页面');
            //    this.$router.push('/bind');
            //   return false;
            // }
            setTimeout(() => {

            //  this.$router.push('/');
             //this.$router.replace('/');
			 that.$router.go(0);
           }, 500);
          }else if(res.code == 0) {
             that.$message.warning(res.msg);
          }
        }).catch(err=>{
            //console.log(err);
            this.$message.error(err.msg);
            loading.close();
        })
    },
    yzmClick() {
      var _this = this;
      if (_this.phoneNum == "") {
        _this.$message.warning("请输入手机号");
        return false;
      }
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(_this.phoneNum) && _this.phoneNum != "") {
        _this.$message.warning("请输入正确的电话号码");
        return false;
      }
      if (!_this.isYzmStatus) {
        _this.$message.warning("请勿重复点击获取");
        return false;
      }
      _this.isYzmStatus = false;
      var params = {
        mobile: _this.phoneNum,
        type: 1,
      };
      getCode(params).then(() => {
        //console.log(res);
        _this.timer = setInterval(() => {
          if (_this.endTime <= 0) {
            _this.isYzmStatus = true;
            _this.yzmMsg = "获取验证码";
            clearInterval(_this.timer);
            return false;
          }
          _this.endTime--;
          _this.yzmMsg = _this.endTime + "s";
        }, 1000);
        _this.$message.success("获取成功");
      });
    },
    submit() {
      //console.log("点击提交");
      if (this.phoneNum == "") {
        this.$message.warning("请输入手机号");
        return false;
      }
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(this.phoneNum) && this.phoneNum != "") {
        this.$message.warning("请输入正确的电话号码");
        return false;
      }
      if (!this.yzmCode) {
        this.$message.warning("请输入正确验证码");
        return false;
      }
      submit({
        phone: this.phoneNum,
        code: this.yzmCode,
      }).then((res) => {
        if (res.code > 0) {
           this.$message.success(res.msg);
           localStorage.setItem("userInfo", JSON.stringify(res.data));
           localStorage.setItem("token", res.data.token);
           this.userInfo = res.data;
           this.dialogVisible = false;
           setTimeout(() => {
             this.$router.replace('/');
           }, 500);
        }else{
           this.yzmCode = '';
           this.$message.error(res.msg);
        }
      });
    },
    getNowDate(){
      const timeOne = new Date();
      return timeOne
    },
    handleClose(){
      this.dialogVisible  =false;
    },
    cityClick(level,label){
      this.show = false;
      if(this.cityName == label){
        label = '';
        level=0;
      }
      this.cityName = label;
      this.city = {
        level,label
      }
      localStorage.setItem("cityName", JSON.stringify(this.city));
      this.$emit('cityChange',this.city)
    },
    titleMouseenter(){
      this.show = true;
    },
    titleMouseleave(){
      this.show = false;
    },
    infoMouseenter(){
      this.userShow = true;
    },
    infoMouseleave(){
      this.userShow = false;
    },
    handleChange(value) {
        console.log(value);
      },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    go(path){
        this.$router.push(path);
        this.path = path;
    },
    logout(){
      localStorage.removeItem('userInfo')
      localStorage.removeItem('token')
      if(this.$route.path != '/'){
        this.$router.replace('/')
      }else{
        this.$router.go();
      }
    },
    handleScroll() {
      var _this = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 90) {
        //滚动大于0的时候要做的操作
        _this.fixedShow = true;
      }if(scrollTop < 100) {
        _this.fixedShow = false;
      }
      if (scrollTop > 200) {
        //大于200的时候要做的操作
        _this.isActive = true;
      } else {
        _this.isActive = false;
      }
      // if (scrollTop > oneHeight) {
      //   //这是滑动到scrollTwo的距离要做的操作
      // }
      // if (scrollTop > twoHeight) {
      //   //这是滑动到scrollThree要做的操作
      // }
    },
    goTop() {
      window.scrollTo({
          top: 0,
          behavior: "smooth"
      })
      // cancelAnimationFrame(this.timer);
      // const self = this;
      // self.timer = requestAnimationFrame(function fn() {
      //   const oTop =
      //     document.body.scrollTop || document.documentElement.scrollTop;
      //   if (oTop > 0) {
      //     document.body.scrollTop = document.documentElement.scrollTop =
      //       oTop - 50;
      //     self.timer = requestAnimationFrame(fn);
      //   } else {
      //     cancelAnimationFrame(self.timer);
      //   }
      // });
    },
    nextClick(item,index) {
      this.actIndex = index;
      if (item.type == 1) {
        this.path = item.path
        this.$router.push(item.path);
      }else{
        window.open(item.path);
      }
    },
	openwin(url) {
		window.open(url);
	},
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/const.scss";
@import "./css/indexHeader.scss";
div{
  line-height: none !important;
}


</style>
