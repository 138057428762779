<template>
    <div class="not-found">
      <div class="title">404</div>
      <div class="info">提示：你请求访问的页面，暂时找不到，我们建议你返回首页官网进行浏览，谢谢！</div>
    </div>
  </template>
  <script>
  export default {
    name: 'NotFound',
    computed: {
      message() {
        return 'The webmaster said that you can not enter this page...'
      }
    }
  }
  </script>
  <style lang="scss" scoped>
  .not-found{
    display: flex;
    flex-direction: column;
    align-items: center;
    .title{
        font-size:.78rem;
        font-weight: bold;
        margin-top: .52rem;
    }
    .info{
        font-size:.1rem;
        margin-top: .26rem;
    }
  }
  </style>