<template>
  <header class="header_nav" :class="{'header_nav_fixed':fixedShow}">
    <div class="content">
      <a href="/"> <img src="@/assets/images/logo.png" class="logo" /></a>
      <!-- <header-nav /> -->
    </div>
  </header>
</template>

<script>
// import HeaderNav from './HeaderNav'

export default {
  name: 'PageHeader',
  components: {
    // HeaderNav,

  },
  data(){
    return {
      fixedShow:false,

    }
  },
  mounted() {
    var _this = this;
    window.addEventListener("scroll", _this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      var _this = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop);
      if (scrollTop > 100) {
        //滚动大于0的时候要做的操作
        _this.fixedShow = true;
      }if(scrollTop < 100) {
        _this.fixedShow = false;
      }
    },
  }

}
</script>

<style scoped lang="scss">
@import "~@/assets/styles/const.scss";
.element::-webkit-scrollbar { width: 0 !important }

.header_nav_fixed{
  width:100%;
  position: fixed;
  z-index:1000;
}
.header_nav {
  flex: 0 0 0.47rem;
  z-index: 4;
  background: #fff;
  box-shadow: 0rem 0rem 0.04rem 0rem rgba(24, 51, 77, 0.08);
  .content {
    width:$content-width;
    // min-width:$content-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 0.47rem;
  }
  .logo {
    height: 0.26rem;
  }
}
</style>
